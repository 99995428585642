<template>
<section class="px-3">
    <n-alert
        v-if="marketplace.localization?.['marketplace/signals/info']"
        class="rounded-lg" type="warning" size="small">
        <template #header>
            <n-collapse arrow-placement="right" @item-header-click="showInfo = !showInfo">
                <n-collapse-item>
                    <template #header>
                        <div class="text-[10px]" v-html="info"></div>
                    </template>
                </n-collapse-item>
            </n-collapse>
        </template>
    </n-alert>

    <n-tabs
        class="mt-4"
        type="segment"
        :value="currentTab"
        @update:value="changeTab">
        <n-tab-pane
            v-for="(tab, i) in tabs"
            :key="i"
            :name="tab.name"
            :tab="tab.title"
            :disabled="loading">
            <router-view></router-view>
        </n-tab-pane>
    </n-tabs>
</section>
</template>

<script>
// vue
import { ref, reactive, computed } from 'vue';

// store
import { useRefs } from '@store/ts/refs';
import { useMarketplace } from '@store/ts/marketplace';

// router
import { useRoute, useRouter } from 'vue-router';

// components
import FilterExchanges from '@components/filters/exchanges';
import FilterButtonGroup from '@components/filters/button-group.vue';

// naive ui
import {
    NCard,
    NAlert,
    NTabs,
    NTabPane,
    NCollapse,
    NCollapseItem } from 'naive-ui';

export default {
    name: 'signals-mobile',
    components: {
        NCard,
        NAlert,
        NTabs,
        NTabPane,
        NCollapse,
        NCollapseItem,
        FilterExchanges,
        FilterButtonGroup,
    },
    setup() {
        // store
        const refs = useRefs();
        const marketplace = useMarketplace();

        // vars
        const showInfo = ref(false);

        // router
        const route = useRoute();
        const router = useRouter();

        const currentTab = computed(() => {
            if (route.name === 'marketplace.signals.avail-signals') {
                return ''; 
            } else if (route.name.includes('marketplace.signals.my-signals')) {
                return 'my-signals'; 
            }
            return 'buy';
        });

        const tabs = computed(() => [
            {
                name: '',
                title: marketplace.localization?.['marketplace/signals/tabs/avail/title'],
            }, {
                name: 'my-signals',
                title: marketplace.localization?.['marketplace/signals/tabs/my/title'],
            },
        ]);

        const info = computed(() => {
            return !showInfo.value
                ? marketplace.localization?.['marketplace/signals/info'].slice(0, 70)
                : marketplace.localization?.['marketplace/signals/info'];
        });

        const changeTab = tab => void router.push({ path: `/marketplace/signals/${tab}` });

        return {
            refs,
            tabs,
            info,
            showInfo,
            currentTab,
            marketplace,
            changeTab,
        };
    },
};
</script>

<style lang="scss" scoped>
:deep(.n-tabs) {
  .n-tabs-wrapper  {
    padding: 0 12px;
  }
  .n-tabs-tab .n-tabs-tab__label {
    font-size: 0.6rem !important;
    line-height: 1rem !important;
  }
}
</style>