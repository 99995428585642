<template>
<n-alert
    v-if="marketplace.localization?.['marketplace/signals/info']"
    class="rounded-lg" type="warning" size="large">
    <div
        class="notes text-xs"
        v-html="marketplace.localization?.['marketplace/signals/info']">
    </div>
</n-alert>

<n-tabs
    class="mt-4"
    type="line"
    :value="currentTab"
    @update:value="changeTab">
    <n-tab-pane
        v-for="(tab, i) in tabs"
        :key="i"
        :name="tab.name"
        :tab="tab.title"
        :disabled="loading">
        <router-view></router-view>
    </n-tab-pane>
</n-tabs>
</template>

<script>
// vue
import { ref, reactive, computed } from 'vue';

// store
import { useRefs } from '@store/ts/refs';
import { useMarketplace } from '@store/ts/marketplace';

// router
import { useRoute, useRouter } from 'vue-router';

// components
import FilterExchanges from '@components/filters/exchanges';
import FilterButtonGroup from '@components/filters/button-group.vue';

// naive ui
import {
    NCard,
    NAlert,
    NTabs,
    NTabPane,
} from 'naive-ui';

export default {
    name: 'signals-desktop',
    components: {
        NCard,
        NAlert,
        NTabs,
        NTabPane,
        FilterExchanges,
        FilterButtonGroup,
    },
    setup() {
        // store
        const refs = useRefs();
        const marketplace = useMarketplace();

        // router
        const route = useRoute();
        const router = useRouter();

        const currentTab = computed(() => {
            if (route.name === 'marketplace.signals.avail-signals') {
                return ''; 
            } else if (route.name.includes('marketplace.signals.my-signals')) {
                return 'my-signals'; 
            }
            return 'buy';
        });

        const tabs = computed(() => [
            {
                name: '',
                title: marketplace.localization?.['marketplace/signals/tabs/avail/title'],
            }, {
                name: 'my-signals',
                title: marketplace.localization?.['marketplace/signals/tabs/my/title'],
            },
        ]);

        const changeTab = tab => void router.push({ path: `/marketplace/signals/${tab}` });

        return {
            refs,
            tabs,
            currentTab,
            marketplace,
            changeTab,
        };
    },
};
</script>